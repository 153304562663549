<template>
  <Dialog :common-dialog="dialog" :dialog-width="dialogWidth">
    <template v-slot:title>Create Project </template>
    <template v-slot:body>
      <v-container>
        <v-layout>
          <v-row>
            <v-col md="12">
              <div>
                <label class="font-weight-700 font-size-16 required"
                  >Project Title
                </label>
                <v-text-field
                  v-model.trim="projectCreate.name"
                  dense
                  filled
                  color="cyan"
                  label="Project Name"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                ></v-text-field>
              </div>
            </v-col>
            <v-col md="6">
              <div>
                <label class="font-weight-700 font-size-16">Start Date </label>
                <DatePicker solo :placeholder="'Start Date *'" :default-date="
                projectCreate.started_at " v-model="projectCreate.started_at"
                mandatory: true />
              </div>
            </v-col>
            <v-col md="6">
              <div>
                <label class="font-weight-700 font-size-16">End Date </label>
                <DatePicker solo :placeholder="'End Date'" :default-date="
                projectCreate.deadline " :min-date="projectCreate.started_at"
                v-model="projectCreate.deadline" mandatory: true />
              </div>
            </v-col>
          </v-row>
        </v-layout>
      </v-container>
    </template>
    <template v-slot:action>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button white--text"
        color="cyan"
        v-on:click="$emit('close', true)"
      >
        Save
      </v-btn>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="$emit('close', true)"
      >
        Cancel
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import { toSafeInteger } from "lodash";
import Dialog from "@/view/pages/partials/Dialog.vue";
import DatePicker from "@/view/pages/partials/Datepicker.vue";

export default {
  name: "opportunity",
  data() {
    return {
      pageLoading: false,
      formLoading: false,
      addProjectDialog: false,
      projectCreate: {
        name: null,
        started_at: null,
      },
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Dialog,
    DatePicker,
  },

  computed: {
    dialogWidth() {
      return toSafeInteger((document.body.clientWidth / 100) * 50);
    },
  },
};
</script>
